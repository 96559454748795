export default {
  blogerName: 'FECAMP',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@eufelipecampagnani/featured',
    },
    {
      name: 'telegram',
      url: 'https://t.me/fecampagnani',
    }
  ],
  projects: [
    {
      name: 'lex',
      url: 'https://lex-irrs01.com/cd4c8d25a',
      gameTitle: 'Book of Kemet',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: '1go',
      url: 'https://1go-irit.com/c524f8d1f',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs12.com/cdd320cd0',
      gameTitle: 'Alien Fruits ',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs10.com/c72e89419',
      gameTitle: 'STARDA QUEEN',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/c47f657b1',
      gameTitle: 'Doors of Sol',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/c8ee0642b',
      gameTitle: 'IZZI ART',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs10.com/c7444091c',
      gameTitle: 'LEGZO PUNK',
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs12.com/c38560f48',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/c06491d6f',
      gameTitle: 'Doors Of Fresh',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/c40d3a1b3',
      gameTitle: 'Jet Air',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>FECAMP</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'FECAMP',
  casinoBonus1: '<strong>100% + 500FS</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>para registro con código promocional</div>',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
  attentionText: ''
}
